<script>
import _merge from "lodash/merge";
import listingBox from "@/components/listing-box.vue";
import { wpService } from "@/services/wp";
// import fieldPrice from "@/components/field-price.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    listingBox,
    fieldPrice: () =>
      import(
        /* webpackChunkName: "field-price" */ "@/components/field-price.vue"
      ),
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      editing: false,
      clonedValues: null,
    };
  },
  created() {
    this.reset();
  },
  computed: {
    tradesIn() {
      let any = [];
      for (const key in this.temp_trades) {
        if (this.entry[key]) any.push(key);
      }
      return any;
    },
    currentValues() {
      let prices = {};
      for (const key in this.temp_trades) {
        prices[key] = this.entry[key];
      }
      return prices;
    },
    canSubmit() {
      let canSubmit = {};
      for (const key in this.clonedValues) {
        if (this.clonedValues[key] !== this.currentValues[key])
          canSubmit[key] = this.clonedValues[key];
      }
      if (Object.keys(canSubmit).length) return canSubmit;
      return false;
    },
    ...mapGetters("config", ["temp_trades"]),
  },
  methods: {
    reset() {
      this.clonedValues = _merge({}, this.currentValues);
    },
    submit() {
      let loading = this.$buefy.loading.open({ container: null });
      wpService.listings
        .patchSingle(this.entry.ltg_id, this.canSubmit)
        .then(() => {
          this.editing = false;
          this.$buefy.toast.open({
            message: "¡Variantes de precio actualizadas!",
            type: "is-success",
          });
          loading.close();
        })
        .catch(err => {
          console.log(err);
          loading.close();
        });
    },
  },
  watch: {
    editing() {
      this.reset();
    },
  },
};
</script>

<template>
  <listingBox
    title="Variantes de precio"
    editable
    :editing="editing"
    @editing="editing = $event"
  >
    <div class="prices" v-if="!editing && tradesIn.length">
      <template v-for="(data, key) in temp_trades">
        <div v-if="entry[key]" :key="key" class="price">
          <div class="price__label">{{ data.label }}:</div>
          <div class="price__value">
            <span class="price__currency">USD</span>
            ${{ entry[key] | formatMoney(0) }}
          </div>
        </div>
      </template>
    </div>
    <div v-else-if="!editing" class="readable">
      <b-message type="is-danger">
        <b-icon icon="exclamation-circle" />
        <!-- <b>Debe asignar un valor mayor a 0 por lo menos 1 categoría.</b> -->
        <span><b>Requerido:</b> Especificar un valor en alguna categoría.</span>
      </b-message>
    </div>
    <div v-else>
      <fieldPrice
        v-for="(data, key) in temp_trades"
        :key="key"
        :label="data.label"
        :precision="0"
        v-model="clonedValues[key]"
      />
      <div class="submit">
        <b-button type="is-primary" :disabled="!canSubmit" @click="submit">
          Guardar
        </b-button>
      </div>
    </div>
  </listingBox>
</template>

<style lang="scss" scoped>
.price {
  justify-content: space-between;
  padding: 0.375rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  .price__label {
    font-weight: 500;
  }
}
@media (min-width: 26em) {
  .field {
    display: flex;
    align-items: center;
    /deep/ .label {
      flex: 0 0 60%;
      margin-bottom: 0;
    }
    /deep/ .input {
      // max-width: 10rem;
      flex: 1 1 10rem;
    }
  }
}
</style>
